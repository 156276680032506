import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CitySelect from '../components/CitySelect'; // Import CitySelect component
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
  const { t } = useTranslation();

  const [activity, setActivity] = useState(''); // Search for the activity keyword
  const [city, setCity] = useState(''); // Search for the city keyword

  const navigate = useNavigate(); // For navigating to the activity list page

  const handleSearch = () => {
    // Navigate to the activity list page with search params
    const queryParams = new URLSearchParams({
      sport: activity,
      location: city,
    }).toString();

    navigate(`/activities?${queryParams}`);
  };

  const handleCityChange = (selectedOption) => {
    setCity(selectedOption ? selectedOption.value : '');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        padding: '4px',
        width: { xs: '100%', md: '350px' }, // Full width on mobile, fixed width on desktop
      }}
    >
      {/* Activity Input */}
      <InputBase
        placeholder={t('search_placeholder')}
        value={activity}
        onChange={(e) => setActivity(e.target.value)}
        sx={{
          padding: '4px',
          flexGrow: 3, // Make the activity search take more space
        }}
      />

      {/* Separator */}
      <Box
        sx={{
          width: '1px',
          height: '24px',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        }}
      />

      {/* City Input using CitySelect */}
      <Box sx={{ flexGrow: 1, minWidth: '150px' }}>
        <CitySelect
          value={city}
          onChange={handleCityChange}
          placeholder={t('city_placeholder')}
          customStyles={{
            control: {
              padding: '4px', // Matches InputBase padding
              border: 'none', // Remove border to match InputBase design
              boxShadow: 'none', // Remove shadow
              minHeight: 'inherit', // Maintain height consistency
            },
            input: {
              padding: '4px', // Padding for the input
            },
          }}
        />
      </Box>

      {/* Search Icon Button */}
      <IconButton sx={{ padding: '4px' }} onClick={handleSearch}>
        <SearchIcon color="action" />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
