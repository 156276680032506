import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next'; // Move this to the top

import { Typography, Box, Grid, CircularProgress, Card, CardContent, useTheme, useMediaQuery } from '@mui/material';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line } from 'recharts';
import axios from 'axios';
import { UserContext } from '../../../contexts/UserContext';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Home = () => {
  const { t } = useTranslation();

  const { isLoggedIn, userInfo, loading: userLoading } = useContext(UserContext);
  const [adminData, setAdminData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Theme and media query for responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sessionToken = userInfo.sessionToken;

  useEffect(() => {
    const fetchData = async () => {
      if (!sessionToken) {
        console.error("No session token found");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/dashboard`, {
          headers: {
            Authorization: `Bearer ${userInfo.sessionToken}`
          },
        });
        setAdminData(response.data);
      } catch (error) {
        console.error('Error fetching admin data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isLoggedIn && userInfo?.isAdmin) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [isLoggedIn, userInfo]);

  // Data for charts
  const pieData = [
    { name: 'Active Activities', value: adminData?.activeActivities || 0 },
    { name: 'Inactive Activities', value: (adminData?.totalActivities || 0) - (adminData?.activeActivities || 0) },
  ];

  const barData = [
    {
      name: 'Stats',
      TotalUsers: adminData?.totalUsers || 0,
      ActiveUsers: adminData?.numberOfActiveUsers || 0,
      NewRegistrations: adminData?.newUsersLastMonth || 0,
      NewSubscriptions: adminData?.newSubscriptionsLastMonth || 0,
    },
  ];

  const lineChartData = [
    { name: 'Week 1', Activities: adminData?.activitiesOverTime[3] || 0 },
    { name: 'Week 2', Activities: adminData?.activitiesOverTime[2] || 0 },
    { name: 'Week 3', Activities: adminData?.activitiesOverTime[1] || 0 },
    { name: 'Week 4', Activities: adminData?.activitiesOverTime[0] || 0 },
  ];

  if (userLoading || loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
      {t('admin_home')}
      </Typography>
      <Typography variant="body1" gutterBottom>
      {t('admin_welcome_message')}
      </Typography>

      {/* Responsive Grid for Summary Cards and Charts */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* Summary Cards */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6} sm={3}>
            <Card sx={{ textAlign: 'center' }}>
              <CardContent>
                <Typography variant="subtitle2">{t('total_users')}</Typography>
                <Typography variant="h5">{adminData?.totalUsers}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card sx={{ textAlign: 'center' }}>
              <CardContent>
                <Typography variant="subtitle2">{t('active_users')}</Typography>
                <Typography variant="h5">{adminData?.numberOfActiveUsers}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card sx={{ textAlign: 'center' }}>
              <CardContent>
                <Typography variant="subtitle2">{t('new_registrations_last_month')}</Typography>
                <Typography variant="h5">{adminData?.newUsersLastMonth}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card sx={{ textAlign: 'center' }}>
              <CardContent>
                <Typography variant="subtitle2">{t('new_subscriptions_last_month')}</Typography>
                <Typography variant="h5">{adminData?.newSubscriptionsLastMonth}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Pie Chart for Active vs Inactive Activities */}
        <Grid item xs={12} md={4}>
          <Card sx={{ textAlign: 'center', height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              {t('active_vs_inactive_activities')}
              </Typography>
              <PieChart width={isMobile ? 250 : 300} height={isMobile ? 200 : 250}>
                <Pie
                  data={pieData}
                  cx={isMobile ? 125 : 150}
                  cy={isMobile ? 100 : 125}
                  outerRadius={isMobile ? 60 : 80}
                  fill="#8884d8"
                  dataKey="value"
                  label={!isMobile}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </CardContent>
          </Card>
        </Grid>

        {/* Bar Chart for Users and Subscriptions Statistics */}
        <Grid item xs={12} md={4}>
          <Card sx={{ textAlign: 'center', height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              {t('users_and_subscriptions_statistics')}
              </Typography>
              <BarChart
                width={isMobile ? 250 : 300}
                height={isMobile ? 200 : 250}
                data={barData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="TotalUsers" fill="#8884d8" />
                <Bar dataKey="ActiveUsers" fill="#82ca9d" />
                <Bar dataKey="NewRegistrations" fill="#ffc658" />
                <Bar dataKey="NewSubscriptions" fill="#d884ff" />
              </BarChart>
            </CardContent>
          </Card>
        </Grid>

        {/* Line Chart for Activities Over Time */}
        <Grid item xs={12} md={4}>
          <Card sx={{ textAlign: 'center', height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              {t('activities_over_time')}
              </Typography>
              <LineChart
                width={isMobile ? 250 : 300}
                height={isMobile ? 200 : 250}
                data={lineChartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Activities" stroke="#8884d8" />
              </LineChart>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
