import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Typography, Tabs, Tab, Box, Grid } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import ActivityCard from '../../components/ActivityCard';
import CreateActivityForm from './CreateActivityForm';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function MyActivities() {
  const { t } = useTranslation();

  const { userInfo } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (userInfo && userInfo._id) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/user/${userInfo._id}`, {
        headers: {
          Authorization: `Bearer ${userInfo.sessionToken}`
        }
      })
        .then(response => response.json())
        .then(data => setActivities(data))
        .catch(err => console.error('Failed to fetch activities:', err));
    }
  }, [userInfo]);

  console.log("activities", activities);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const now = new Date();
  const futureDate = new Date(now);
  futureDate.setDate(futureDate.getDate() + 30);
  
  // Filter for upcoming activities based on date, status, and approved reviewStatus
  const upcomingActivities = activities.filter(a => 
    new Date(a.date) >= now && 
    new Date(a.date) <= futureDate && 
    a.status === 'active' && 
    a.reviewStatus === 'approved'
  );
  
  // Filter for far-away activities based on date, status, and approved reviewStatus
  const farAwayActivities = activities.filter(a => 
    new Date(a.date) > futureDate && 
    a.status === 'active' && 
    a.reviewStatus === 'approved'
  );
  
  // Filter for past activities, including canceled and rejected ones
  const pastActivities = activities.filter(a => 
    new Date(a.date) < now || 
    a.status === 'canceled' || 
    a.reviewStatus === 'rejected'
  );

  // Filter for pending activities
  const pendingActivities = activities.filter(a => a.reviewStatus === "pending");

  const renderActivities = (activityList) => {
    if (activityList.length === 0) {
      return <Typography variant="subtitle1">{t('no_activities_available')}</Typography>;
    }
    return (
      <Grid container spacing={2}>
        {activityList.map(activity => (
          <Grid item xs={12} sm={6} md={4} key={activity._id}>
            <ActivityCard activity={activity} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
      {t('my_activities_title')}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>
      {t('create_new_activity')}
      </Button>

      <CreateActivityForm open={open} handleClose={handleClose} />

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="activity categories">
        <Tab label={t('upcoming')} {...a11yProps(0)} />
        <Tab label={t('far_away')} {...a11yProps(1)} />
        <Tab label={t('past_activities')} {...a11yProps(2)} />
        <Tab label={t('pending')} {...a11yProps(3)} /> {/* New tab for Pending activities */}
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        {renderActivities(upcomingActivities)}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {renderActivities(farAwayActivities)}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {renderActivities(pastActivities)}
      </TabPanel>
      <TabPanel value={tabValue} index={3}> {/* Panel for Pending activities */}
        {renderActivities(pendingActivities)}
      </TabPanel>
    </Container>
  );
}

export default MyActivities;
