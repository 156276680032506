import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import ReportButton from './ReportButton';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', padding: '16px 0', marginTop: '30px' }}>
      <Container maxWidth="lg">
        <Typography variant="body1" align="center" gutterBottom>
          &copy; {new Date().getFullYear()} {t('footer_copyright')}
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary">
          <Link href="/about" color="inherit">
          {t('footer_about')}
          </Link>
          {' | '}
          <Link href="/terms" color="inherit">
          {t('footer_terms')}
          </Link>
          {' | '}
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
            <ReportButton
              type="assistance"
              buttonText={t('footer_assistance')}
              buttonStyle="black"
              onSuccess={() => console.log('Help request submitted!')}
              textOnly={true}
            />
          </span>

        </Typography>

      </Container>
    </Box>
  );
}

export default Footer;
