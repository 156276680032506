import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Button, List, ListItem, ListItemAvatar, ListItemText, Avatar, Chip, Stack, TextField, Paper } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import PaymentIcon from '@mui/icons-material/Payment';
import EditActivityForm from './EditActivityForm';  // Form for editing the activity
import { useNotification } from '../../contexts/NotificationContext'; // Import the notification context
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';


const OrganizerDashboard = ({ activity, onUpdateActivity, onSendNotification }) => {
    const { t } = useTranslation();
    const { userInfo, refreshUserInfo } = useContext(UserContext);

    const [participants, setParticipants] = useState([]); // Participants from backend
    const [totalCollected, setTotalCollected] = useState(0);
    const [paidParticipantsCount, setPaidParticipantsCount] = useState(0);
    const [notificationText, setNotificationText] = useState('');
    const [openEditModal, setOpenEditModal] = useState(false);  // For opening the edit modal
    const [totalTransferred, setTotalTransferred] = useState(0);
    const [paymentCollected, setPaymentCollected] = useState(false);

    const { showNotification } = useNotification(); // Fix: destructure correctly

    // Fetch participants from backend
    const fetchParticipants = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activity._id}/participants`, {
                headers: {
                    Authorization: `Bearer ${userInfo.sessionToken}`,
                }
            });
            const data = await response.json();
            setParticipants(data);
        } catch (err) {
            console.error('Failed to fetch participants:', err);
        }
    };

    // Fetch payment status from backend
    const fetchPaymentStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activity._id}/payment-status`, {
                headers: {
                    Authorization: `Bearer ${userInfo.sessionToken}`,
                }
            });
            const data = await response.json();

            setTotalCollected(data.totalCollected || 0);  // Ensure default value if undefined
            setTotalTransferred(data.totalTransferred || 0);  // Ensure default value if undefined
            setPaymentCollected(data.paymentCollected);  // whether payments are already collected
        } catch (err) {
            console.error('Failed to fetch payment status:', err);
        }
    };

    // useEffect to fetch participants and payment status on component mount
    useEffect(() => {
        fetchParticipants();
        fetchPaymentStatus();
    }, [activity._id]);

    // Calculate total amount collected and number of paid participants
    useEffect(() => {
        const total = participants.reduce((acc, participant) => acc + participant.amountPaid, 0);
        const paidCount = participants.filter(participant => participant.paymentStatus === 'paid').length;
        setTotalCollected(total / 100);  // Convert total collected to euros
        setPaidParticipantsCount(paidCount);
    }, [participants]);

    // Handle opening and closing the edit modal
    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    // Handle activity updates
    const handleEditActivity = async (updatedData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activity._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });

            if (response.ok) {
                const updatedActivity = await response.json();
                onUpdateActivity(updatedActivity);
                showNotification(t('activity_updated_successfully'), 'success');
            } else {
                showNotification(t('failed_to_update_activity'), 'error');
            }
        } catch (error) {
            console.error(t('failed_to_update_activity'), error);
        }

        handleCloseEditModal();
    };

    // Handle canceling the activity
    const handleCancelActivity = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activity._id}/cancel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                showNotification(t('activity_canceled_successfully'), 'success');
            } else {
                showNotification(t('failed_to_cancel_activity'), 'error');
            }
        } catch (error) {
            console.error(t('failed_to_cancel_activity'), error);
        }
    };

    // Handle removing a participant
    const handleRemoveParticipant = async (participantId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activity._id}/remove-participant`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ participantId }),
            });

            if (response.ok) {
                setParticipants(prev => prev.filter(participant => participant.userId._id !== participantId));
                showNotification(t('participant_removed_successfully'), 'success');
            } else {
                const data = await response.json();
                showNotification(`${t('failed_to_remove_participant')}: ${data.message}`, 'error');
            }
        } catch (error) {
            console.error(t('failed_to_remove_participant'), error);
        }
    };

    // Handle sending notification to participants
    const handleSendNotification = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/${activity._id}/notify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: notificationText }),
            });

            if (response.ok) {
                setNotificationText(''); // Clear notification text
                showNotification(t('notification_sent_successfully'), 'success');
            } else {
                showNotification(t('failed_to_send_notification'), 'error');
            }
        } catch (error) {
            console.error('Failed to send notification:', error);
        }
    };

    // Handle collecting payments
    const handleCollectPayments = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities/collect-payments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.sessionToken}`
                },
                body: JSON.stringify({
                    activityId: activity._id,
                    organizerStripeAccountId: activity.stripeAccountId,
                }),
            });

            const data = await response.json();
            if (response.ok) {
                showNotification(t('payments_collected_successfully'), 'success');
                setPaymentCollected(true);  // Update the state to show that payments are collected
                await fetchPaymentStatus();  // Fetch updated payment status after collection
                await fetchParticipants();   // Fetch updated participant list after collection
            } else {
                showNotification(`${data.message}`, 'error');
            }
        } catch (error) {
            showNotification(t('failed_to_collect_payments'), 'error');
            console.error('Error collecting payments:', error);
        }
    };

    return (
        <Box sx={{ mt: 3 }}>
            {/* Activity Details Management */}
            <Stack direction="row" spacing={2} sx={{ margin: "0 auto", width: "fit-content", marginBottom: "20px" }}>
                <Button variant="contained" startIcon={<EditIcon />} color="primary" onClick={handleOpenEditModal}>
                {t('edit_activity')}
                </Button>
                <Button variant="outlined" startIcon={<CancelIcon />} color="error" onClick={handleCancelActivity}>
                {t('cancel_activity')}
                </Button>
            </Stack>

            {/* Payment Management (only if cost > 0) */}
            {activity.cost > 0 && (
                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                    {t('payments_section')}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <EuroIcon color="action" />
                            <Typography variant="body1">
                            {t('balance')}: {t('currency_txt')}{(totalCollected || 0).toFixed(2)}  {/* Show total collected */}
                            </Typography>
                            {paymentCollected ? (
                                <Typography variant="body2" color="success">
                                    {t('payment_collected')}
                                </Typography>
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleCollectPayments}>
                                    {t('collect_payments0')}
                                </Button>
                            )}
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="body1">
                            {t('total_collected0')}: {t('currency_txt')}{(totalTransferred || 0).toFixed(2)}  {/* Show total transferred */}
                            </Typography>
                        </Stack>
                    </Stack>
                </Paper>
            )}

            {/* Participant Management */}
            <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                {t('participants_section')}
                </Typography>

                {participants.length === 0 ? (
                    <Typography variant="body2">{t('no_participants')}</Typography>
                ) : (
                    <List sx={{ maxHeight: 250, overflowY: 'auto' }}>
                        {participants.map((participant) => (
                            <ListItem key={participant._id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <ListItemAvatar>
                                    <Avatar src={process.env.REACT_APP_BACKEND_URL + participant.userId.avatar} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={participant.userId.username}
                                    secondary={
                                        participant.paymentStatus === 'paid'
                                            ? t('participant_paid')
                                            : participant.paymentStatus === 'transferred'
                                                ? t('participant_transferred')
                                                : t('participant_pending_payment')
                                    }
                                />
                                <Chip
                                    icon={<PaymentIcon />}
                                    label={`€${(participant.amountPaid / 100).toFixed(2)} - ${participant.paymentStatus === 'paid'
                                            ? t('participant_paid')
                                            : participant.paymentStatus === 'transferred'
                                                ? t('participant_transferred')
                                                : t('participant_pending_payment')
                                        }`}  // Convert cents to euros and show status
                                    color={
                                        participant.paymentStatus === 'paid'
                                            ? 'success'
                                            : participant.paymentStatus === 'transferred'
                                                ? 'primary'
                                                : 'error'
                                    }  // Change color based on payment status
                                    sx={{ mr: 2 }}
                                />
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleRemoveParticipant(participant.userId._id)}  // Ensure you are passing userId._id, not subscription._id
                                >
                                    {t('remove_participant')}
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Paper>

            {/* Communication with Participants */}
            <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                {t('notify_participants')}
                </Typography>
                <TextField
                    label={t('send_notification')}
                    multiline
                    fullWidth
                    rows={3}
                    value={notificationText}
                    onChange={(e) => setNotificationText(e.target.value)}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <Button variant="contained" startIcon={<SendIcon />} onClick={handleSendNotification}>
                {t('send_notification')}
                </Button>
            </Paper>

            {/* Edit Activity Modal */}
            <EditActivityForm open={openEditModal} handleClose={handleCloseEditModal} activity={activity} onUpdateActivity={handleEditActivity} />
        </Box>
    );
};

export default OrganizerDashboard;
