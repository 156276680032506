import React, { useContext, useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Avatar,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Divider,
  ListItemText,
  ListItemIcon,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.svg'; // Import the logo image
import EventNoteIcon from '@mui/icons-material/EventNote';
import SearchBar from './SearchBar'; // Import the new SearchBar component
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReportButton from './ReportButton';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'; // Import flag component


function Header({ onLoginOpen, onRegisterOpen }) {
  const { t, i18n } = useTranslation();  // Get t function and i18n instance
  const [langAnchorEl, setLangAnchorEl] = useState(null); // For language menu
  const langMenuOpen = Boolean(langAnchorEl);

  const { isLoggedIn, userInfo, notifications, markNotificationAsRead } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null); // For avatar menu
  const [notifAnchorEl, setNotifAnchorEl] = useState(null); // For notification menu
  const open = Boolean(anchorEl);
  const notifOpen = Boolean(notifAnchorEl);
  const navigate = useNavigate(); // Initialize useNavigate

  const [selectedNotification, setSelectedNotification] = useState(null);

  // Language menu open/close handlers
  const handleLangMenuOpen = (event) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setLangAnchorEl(null);
  };

  // Handle language change
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);  // Change the language
    handleLangMenuClose();  // Close the language menu after selecting
  };

  // Function to handle when a notification is clicked
  const handleNotificationClick = (notificationId) => {
    const notification = notifications.find((notif) => notif._id === notificationId);
    if (notification) {
      setSelectedNotification(notification);
      // Optionally, here you can add logic to mark the notification as read, for example:
      notification.read = true;
      markNotificationAsRead(notificationId);
      handleNotifClose();
    }
  };

  // Function to close the modal
  const handleModalClose = () => {
    setSelectedNotification(null);
  };

  // Avatar menu open/close handlers
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Notification menu open/close handlers
  const handleNotifOpen = (event) => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleNotifClose = () => {
    setNotifAnchorEl(null);
  };

  // Filter unread notifications
  const unreadNotifications = notifications ? notifications.filter((notif) => !notif.read) : [];
  const unreadCount = unreadNotifications.length;

  // Function to handle clicking on a notification
  /*  const handleNotificationClick = (notificationId) => {
     markNotificationAsRead(notificationId);
     handleNotifClose();
   };
  */
  // Function to navigate to the account page
  const handleAccountClick = () => {
    navigate('/account');
    handleMenuClose();
  };

  // Function to navigate to the account page
  const handlePaymentsClick = () => {
    navigate('/payments');
    handleMenuClose();
  };

  // Function to navigate to the account page
  const handleMyActivityClick = () => {
    navigate('/myactivities');
    handleMenuClose();
  };

  // Function to navigate to the account page
  const handleMyLikesClick = () => {
    navigate('/mylikes');
    handleMenuClose();
  };

  // Function to navigate to the account page
  const handleAdminClick = () => {
    navigate('/admin/dashboard');
    handleMenuClose();
  };

  // Handle redirect to the home page when clicking the logo
  const handleLogoClick = () => {
    navigate('/');
  };

  // Prepare avatar URL
  const avatarUrl = userInfo && userInfo.avatar ? `${process.env.REACT_APP_BACKEND_URL}${userInfo.avatar}` : 'https://via.placeholder.com/40';

  return (
    <AppBar position="static" style={{ background: 'none', boxShadow: 'none', border: 'none', marginBottom: '16px' }}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap', // Allow wrapping on mobile
        }}
      >
        {/* Container for logo and user icons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            width: '100%',
            flexGrow: 0, // Adjust so that the logo and user icons don't grow excessively
          }}
        >
          {/* Clickable logo */}
          <img
            src={logo}
            alt="Build Ma Team Logo"
            style={{ width: '150px', cursor: 'pointer', flexShrink: 0 }} // Prevent logo from shrinking
            onClick={handleLogoClick}
          />

          {/* Spacer to ensure logo and user icons stay in the same line */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} /> {/* Hide spacer on mobile */}



          {/* User icons (notifications, avatar) */}
          <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
            {isLoggedIn && userInfo ? (
              <>
                {isLoggedIn && userInfo?.isAdmin &&
                  <IconButton color="grey" aria-label="likes" onClick={handleAdminClick}>
                    <AdminPanelSettingsIcon />
                  </IconButton>
                }

                <IconButton color="grey" aria-label="likes" onClick={handleMyLikesClick}>
                  <FavoriteBorderIcon />
                </IconButton>

                <IconButton color="grey" aria-label="activities" onClick={handleMyActivityClick}>
                  <EventNoteIcon />
                </IconButton>

                {/* Notifications */}
                <IconButton color="grey" aria-label="notifications" onClick={handleNotifOpen}>
                  <Badge badgeContent={unreadCount} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>

                <Menu
                  anchorEl={notifAnchorEl}
                  open={notifOpen}
                  onClose={handleNotifClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  {notifications && notifications.length === 0 ? (
                    <MenuItem disabled>{t('no_notifications')}</MenuItem>
                  ) : (
                    notifications.map((notification) => (
                      <MenuItem
                        key={notification._id}
                        onClick={() => handleNotificationClick(notification._id)}
                      >
                        <ListItemIcon>
                          <Badge variant="dot" color={!notification.read ? 'error' : 'default'} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            notification.message.length > 50
                              ? `${notification.message.substring(0, 50)}...` // Truncate notification message
                              : notification.message
                          }
                          secondary={new Date(notification.createdAt).toLocaleString()}
                        />
                      </MenuItem>
                    ))
                  )}
                </Menu>

                {/* Modal to display full notification */}
                {selectedNotification && (
                  <Dialog open={!!selectedNotification} onClose={handleModalClose}>
                    <DialogTitle>{t('notifications')}</DialogTitle>
                    <DialogContent>
                      <p>{selectedNotification.message}</p>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleModalClose} color="primary">
                        {t('close')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                {/* User Info (Avatar and Menu) */}
                <IconButton onClick={handleMenuOpen} style={{ marginLeft: '16px' }}>
                  <Avatar alt={userInfo.username} src={avatarUrl} />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <MenuItem disabled>{t('hello')} {userInfo.username}</MenuItem>
                  <MenuItem disabled>{userInfo.email}</MenuItem>

                  <Divider />

                  {/* <MenuItem onClick={handleLangMenuOpen}>
                    {t('language')}
                  </MenuItem>

                  <Menu
                    anchorEl={langAnchorEl}
                    open={langMenuOpen}
                    onClose={handleLangMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <MenuItem onClick={() => handleLanguageChange('en')}>
                      <ListItemIcon>
                        <Flag code="GB" style={{ width: 24, marginRight: 8 }} />
                      </ListItemIcon>
                      <ListItemText>English</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={() => handleLanguageChange('fr')}>
                      <ListItemIcon>
                        <Flag code="FR" style={{ width: 24, marginRight: 8 }} />
                      </ListItemIcon>
                      <ListItemText>Français</ListItemText>
                    </MenuItem>
                  </Menu>

                  <Divider /> */}

                  <MenuItem onClick={handleAccountClick}>{t('account')}</MenuItem>
                  <MenuItem onClick={handlePaymentsClick}>{t('payments')}</MenuItem>
                  <MenuItem>
                    <ReportButton
                      type="suggestion"
                      buttonText={t('suggestion')}
                      buttonStyle="grey"
                      onSuccess={() => console.log('Suggestion idea submitted!')}
                      textOnly={true}
                    />
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem('sessionToken');
                      window.location.reload();
                    }}
                  >
                    <LogoutIcon /> {t('logout')}
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Typography variant="body1" color="textSecondary" style={{ marginRight: '16px', cursor: 'pointer' }} onClick={onLoginOpen}>
                  {t('login')}
                </Typography>
                <Button variant="contained" color="primary" onClick={onRegisterOpen}>
                  {t('register')}
                </Button>
              </>
            )}
          </Box>
        </Box>

        {/* Centered Search Bar */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' }, // Only show on desktop
            justifyContent: 'center', // Center the search bar
            maxWidth: '500px', // Set a max width to avoid it stretching too much
            margin: '0 auto', // Ensure it's centered horizontally
            marginTop: "-50px"
          }}
        >
          <SearchBar />
        </Box>


        {/* Search Bar - Separate container for mobile */}
        <Box
          sx={{
            display: { xs: 'block', md: 'none' }, // Only show on mobile
            width: '100%', // Full width on mobile
            mt: 1, // Add margin on mobile
            order: 3, // Ensure this appears after other elements on mobile
          }}
        >
          <SearchBar />
        </Box>

      </Toolbar>
    </AppBar>

  );
}

export default Header;

