import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Box, Button, Typography, CircularProgress, Divider, Paper, Grid, Link } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTranslation } from 'react-i18next';

const PaymentPage = ({ clientSecret, amount = 0, currency = "EUR", items = [], onPaymentSuccess }) => {
    const { t } = useTranslation();

    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isPaymentComplete, setIsPaymentComplete] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCardComplete, setIsCardComplete] = useState(false);

    useEffect(() => {
        if (errorMessage) {
            setErrorMessage(null);
        }
    }, [elements, errorMessage]);

    const handlePayment = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        setIsProcessing(true);
        const cardElement = elements.getElement(CardElement);

        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });

        setIsProcessing(false);

        if (error) {
            setErrorMessage(error.message);
        } else if (paymentIntent.status === 'succeeded') {
            setIsPaymentComplete(true);
            if (onPaymentSuccess) {
                onPaymentSuccess();  // Trigger the success callback
            }
        }
    };

    const handleCardChange = (event) => {
        setIsCardComplete(event.complete);
        setErrorMessage(event.error ? event.error.message : null);
    };

    return (
        <Box sx={{ maxWidth: 500, margin: '0 auto', padding: 4, textAlign: 'center' }}>
            <Box sx={{ mb: 4 }}>
                <ShoppingCartIcon fontSize="large" sx={{ color: '#1976d2' }} />
                <Typography variant="h5" component="h2" sx={{ mt: 1, fontWeight: 'bold', color: '#1976d2' }}>
                {t('order_summary')}
                </Typography>
                <Paper elevation={2} sx={{ mt: 2, p: 2, borderRadius: 3 }}>
                    {items && items.length > 0 ? (
                        items.map((item, index) => (
                            <Grid container spacing={2} key={index} sx={{ alignItems: 'center', mb: 1 }}>
                                <Grid item xs={8}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                                        {item.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {t('quantity')}: {item.quantity}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                                        {currency} {item.price.toFixed(2)}
                                    </Typography>
                                </Grid>
                                {items.length > 1 && (
                                    <Divider sx={{ width: '100%', mt: 1 }} />
                                )}
                            </Grid>
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            {t('no_items_in_order')}
                        </Typography>
                    )}
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="h6" sx={{ textAlign: 'right', color: '#1976d2', fontWeight: 'bold' }}>
                    {t('total')}: {currency} {amount.toFixed(2)}
                    </Typography>
                </Paper>
            </Box>

            {isPaymentComplete ? (
                <Typography variant="h5" color="success.main" sx={{}}>
                    {t('payment_complete')}
                </Typography>
            ) : (
                <Box>
                    <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
                        <LockIcon fontSize="small" sx={{ verticalAlign: 'middle', color: '#1976d2' }} /> {t('secure_payment')}
                        <Link href="https://stripe.com" target="_blank" sx={{ textDecoration: 'none', ml: 0.5 }}>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Stripe_logo%2C_revised_2014.png/1280px-Stripe_logo%2C_revised_2014.png" alt="Stripe Logo" style={{ height: '20px', verticalAlign: 'middle' }} />
                        </Link>
                    </Typography>

                    <form onSubmit={handlePayment}>
                        <Box sx={{ mb: 3, p: 2, border: '1px solid #1976d2', borderRadius: 3, bgcolor: '#f5f5f5' }}>
                            <CardElement onChange={handleCardChange} options={{ style: { base: { fontSize: '16px', color: '#424770' } } }} />
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={!stripe || !isCardComplete || isProcessing}
                            sx={{
                                py: 1.5,
                                fontWeight: 'bold',
                                fontSize: '1.1rem',
                                backgroundColor: '#1976d2',
                                borderRadius: 3,
                                '&:hover': {
                                    backgroundColor: '#115293',
                                },
                                '&:disabled': {
                                    backgroundColor: '#d1d1d1',
                                    color: '#8a8a8a',
                                },
                            }}
                        >
                            {isProcessing ? <CircularProgress size={24} sx={{ color: '#ffffff' }} /> : t('pay_now')}
                        </Button>
                    </form>
                </Box>
            )}

            {errorMessage && (
                <Typography color="error" sx={{ mt: 2, fontWeight: 'bold' }}>
                    {errorMessage}
                </Typography>
            )}

            {!isPaymentComplete && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="caption" color="textSecondary">
                    {t('if_you_encounter_issues')}{' '}
                        <Link href="mailto:support@example.com" sx={{ color: '#1976d2', textDecoration: 'none' }}>
                        {t('contact_support_email')}
                        </Link>.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default PaymentPage;
