import React, { useState, useRef } from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { useTranslation } from 'react-i18next';

const libraries = ["places"];

const PlaceSearch = ({ onPlaceSelected }) => {
  const { t } = useTranslation();

  const searchBoxRef = useRef(null);

  const handleLoad = (ref) => {
    searchBoxRef.current = ref;
  };

  const handlePlaceChanged = () => {
    const searchBox = searchBoxRef.current;
    const places = searchBox.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];
      const placeInfo = {
        name: place.name,
        address: place.formatted_address,
        photos: place.photos ? place.photos.map(photo => photo.getUrl({ maxWidth: 800 })) : [],
        rating: place.rating,
        addressComponents: place.address_components,
      };

      // Pass the place details to the parent component
      onPlaceSelected(placeInfo);
    }
  };

  return (
    <div>
      <LoadScript googleMapsApiKey="AIzaSyAyyUJojoE-TRpq32ZfQq1yiB1iR5xPmV8" libraries={libraries}>
        <StandaloneSearchBox onLoad={handleLoad} onPlacesChanged={handlePlaceChanged}>
          <input
            type="text"
            placeholder={t('place_search_placeholder')}
            style={{ width: "-webkit-fill-available", height: "30px", padding: "5px" }} // Set input width to 100%
          />
        </StandaloneSearchBox>
      </LoadScript>
    </div>
  );
};

export default PlaceSearch;
