import React, { useContext, useState } from 'react';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import MyStripeAccount from './MyStripeAccount';
import MyPayments from './MyPayments';
import TransfersPage from './TransfersPage';
import { useTranslation } from 'react-i18next';


function Payments() {
  const { t } = useTranslation();

  const { userInfo } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const sessionToken = localStorage.getItem('sessionToken');

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
      {t('my_payments')}
      </Typography>

      {/* Styled Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="payment-related tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Organizer" />
          <Tab label="Payments" />
          <Tab label="Transfers" />
        </Tabs>
      </Box>

      {/* Conditionally Render Content Based on Selected Tab */}
      {selectedTab === 0 && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h5">{t('manage_organizer_account')}</Typography>
          <Typography>{t('organizer_account_status')}</Typography>
          {userInfo && (
            <MyStripeAccount stripeAccountId={userInfo?.stripeAccountId} />
          )}
        </Box>
      )}

      {selectedTab === 1 && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h5">{t('payments_information')}</Typography>
          {/* Payments details component or information can be added here */}
          <Typography>{t('payments_records_info')}</Typography>
          <MyPayments userId={userInfo._id} sessionToken={sessionToken} />
        </Box>
      )}

      {selectedTab === 2 && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h5">{t('transfers_information')}</Typography>
          {/* Transfers details component or information can be added here */}
          <Typography>{t('transfers_records_info')}</Typography>
          <TransfersPage userId={userInfo._id} sessionToken={sessionToken} />

        </Box>
      )}
    </Container>
  );
}

export default Payments;
