import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    Box,
    Typography,
    Grid,
    Select,
    MenuItem,
    FormControl,
    CircularProgress,
    Slider,
    Button,
    Popover,
    TextField,
    IconButton
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventIcon from '@mui/icons-material/Event';
import UpdateIcon from '@mui/icons-material/Update';
import SortIcon from '@mui/icons-material/Sort';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // For creation date sorting
import ActivityCard from '../../components/ActivityCard'; // Assuming you have a card component for each activity

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';   // Import the upward arrow icon
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // Import the downward arrow icon
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';


const ActivityListPage = () => {
    const { t } = useTranslation();
    const { userInfo, refreshUserInfo } = useContext(UserContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [priceRange, setPriceRange] = useState([0, 100]); // Default price range [0, 100]
    const [selectedDate, setSelectedDate] = useState(''); // State for Date input
    const [approachingDays, setApproachingDays] = useState(30); // Default approaching days filter
    const [anchorEl, setAnchorEl] = useState(null); // For popover
    const [activeFilter, setActiveFilter] = useState(null); // To track which filter is active

    // Extract query parameters from URL
    const sort = searchParams.get('sort') || 'date:asc'; // Default sorting by date ascending
    const sport = searchParams.get('sport') || '';
    const location = searchParams.get('location') || '';

    // Fetch activities based on the current query params
    useEffect(() => {
        const fetchActivities = async () => {
            setLoading(true);
            try {
                const query = new URLSearchParams({
                    sport,
                    location,
                    sort,
                }).toString();

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities?${query}`, {
                    headers: {
                        Authorization: `Bearer ${userInfo.sessionToken}`,
                    }
                });
                const data = await response.json();

                setActivities(data.activities);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchActivities();
    }, [sport, location, sort]);

    // Function to update URL query parameters
    const updateSearchParams = (key, value) => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set(key, value);
        setSearchParams(newParams);
    };

    // Handle slider change for price filter
    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    // Handle date filter change
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Define the marks for the slider
    const APPROACHING_MARKS = [
        { value: 1, label: '' },
        { value: 7, label: '' },
        { value: 14, label: '' },
        { value: 30, label: '' },
        { value: 60, label: '' },
        { value: 90, label: '' },
        { value: 100, label: '' },  // 100 represents "All"
    ];

    // Update the handleApproachingDaysChange function to handle "All" correctly
    const handleApproachingDaysChange = (event, newValue) => {
        if (newValue === 100) {
            setApproachingDays(999); // 999 to represent "All"
        } else {
            setApproachingDays(newValue); // Always map directly to days (avoid NaN)
        }
    };


    // In the filter, ensure that the "All" (999) case is handled properly
    const filteredActivities = activities.filter((activity) => {
        const isWithinPriceRange = activity.cost >= priceRange[0] && activity.cost <= priceRange[1];

        const isWithinSelectedDate = selectedDate
            ? new Date(activity.date).toISOString().split('T')[0] === selectedDate
            : true;

        const today = new Date();
        const activityDate = new Date(activity.date);

        // Ensure the "All" case (999) is handled, or calculate the days difference
        const isWithinApproachingDays =
            approachingDays === 999 || (activityDate - today) / (1000 * 60 * 60 * 24) <= approachingDays;

        return isWithinPriceRange && isWithinSelectedDate && isWithinApproachingDays;
    });

    // Popover handlers
    const handlePopoverOpen = (event, filter) => {
        setAnchorEl(event.currentTarget);
        setActiveFilter(filter);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setActiveFilter(null);
    };

    const open = Boolean(anchorEl);

    if (loading) return <Box textAlign="center"><CircularProgress /></Box>;

    return (
        <Box sx={{ p: 3 }}>
            {/* Filters - Compact and Icon-Based */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                {/* Sort By Dropdown */}
                <FormControl sx={{ minWidth: 120 }}>
                    <Select
                        value={sort}
                        onChange={(e) => updateSearchParams('sort', e.target.value)}
                        startAdornment={<SortIcon sx={{ mr: 1 }} />}
                    >
                        <MenuItem value="date:asc">
                            <ArrowUpwardIcon fontSize="small" sx={{ mr: 1 }} />
                            {t('date')}

                        </MenuItem>
                        <MenuItem value="date:desc">
                            <ArrowDownwardIcon fontSize="small" sx={{ mr: 1 }} />
                            {t('date')}
                        </MenuItem>
                        <MenuItem value="createdAt:asc">
                            <ArrowUpwardIcon fontSize="small" sx={{ mr: 1 }} />
                            {t('creation')}
                        </MenuItem>
                        <MenuItem value="createdAt:desc">
                            <ArrowDownwardIcon fontSize="small" sx={{ mr: 1 }} />
                            {t('creation')}
                        </MenuItem>
                    </Select>
                </FormControl>

                {/* Price Filter Button (Icon Only) */}
                <IconButton
                    onClick={(e) => handlePopoverOpen(e, 'price')}
                >
                    <AttachMoneyIcon />
                </IconButton>

                {/* Date Filter Button (Icon Only) */}
                <IconButton
                    onClick={(e) => handlePopoverOpen(e, 'date')}
                >
                    <EventIcon />
                </IconButton>

                {/* Approaching Days Filter Button (Icon Only) */}
                <IconButton
                    onClick={(e) => handlePopoverOpen(e, 'approaching')}
                >
                    <UpdateIcon />
                </IconButton>
            </Box>

            {/* Popovers for filters */}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                {/* Price Filter Popover */}
                {activeFilter === 'price' && (
                    <Box sx={{ p: 2 }}>
                        <Typography gutterBottom>{t('price_range')} {t('currency_txt')}</Typography>
                        <Slider
                            value={priceRange}
                            onChange={handlePriceChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                        />
                        <Typography>{`${t('currency_txt')}${priceRange[0]} - ${t('currency_txt')}${priceRange[1]}`}</Typography>
                    </Box>
                )}

                {/* Date Filter Popover */}
                {activeFilter === 'date' && (
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label={t('select_date')}
                            type="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            sx={{
                                minWidth: { xs: '150px', sm: '200px' }, // Set minimum width for mobile and larger screens
                            }}
                            inputProps={{
                                placeholder: 'yyyy-mm-dd', // Set a placeholder format, adjust as needed
                            }}
                        />
                        {/* Add a reset button next to the date picker */}
                        <Button
                            onClick={() => handleDateChange({ target: { value: '' } })} // Reset the date value
                            sx={{ ml: 2 }} // Add some margin to the left of the button
                            variant="outlined"
                        >
                            {t('reset')}
                        </Button>
                    </Box>
                )}


                {/* Approaching Days Filter Popover */}
                {activeFilter === 'approaching' && (
                    <Box sx={{ p: 2, width: '200px' }}>  {/* Increased width to 300px */}
                        <Typography gutterBottom>{t('approaching_in_days')}</Typography>
                        <Slider
                            value={isNaN(approachingDays) ? 1 : approachingDays === 999 ? 100 : approachingDays} // Prevent NaN
                            onChange={handleApproachingDaysChange}
                            min={1}
                            max={100}  // Ensure max is 100 so "All" can be selected
                            step={null} // Custom steps
                            marks={APPROACHING_MARKS}  // Use custom marks
                        />

                        <Typography>
                            {approachingDays === 999 ? 'All' : `${isNaN(approachingDays) ? 1 : approachingDays} day${approachingDays > 1 ? 's' : ''}`}
                        </Typography>
                    </Box>
                )}
            </Popover>

            {/* Activity Listings */}
            <Grid container spacing={3} justifyContent="center">
                {filteredActivities.length > 0 ? (
                    filteredActivities.map((activity) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={activity._id}
                            display="flex"
                            justifyContent="center"
                            alignItems="stretch" // Ensure uniform height if needed
                            textAlign="center" // Add this to center content on mobile screens
                        >
                            <Box
                                sx={{
                                    maxWidth: '100%',
                                    width: { xs: '100%', sm: '100%', md: '100%' }, // Adjust the width based on screen size
                                    margin: '0 auto', // Ensures the element is centered
                                }}
                            >
                                <ActivityCard activity={activity} />
                            </Box>
                        </Grid>
                    ))
                ) : (
                    <Typography>{t('no_activities_found')}</Typography>
                )}
            </Grid>

        </Box>
    );
};

export default ActivityListPage;

