import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box, Chip, Link, Stack } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import TagIcon from '@mui/icons-material/Tag';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import MiniActivityCard from './MiniActivityCard'; // Adjust the path if necessary
import { useTranslation } from 'react-i18next';

function Events() {
  const { t } = useTranslation();

  const { userInfo } = useContext(UserContext);
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState('Nice');
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && userInfo.city) {
      setLocation(userInfo.city);
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            fetchCityFromCoordinates(position.coords.latitude, position.coords.longitude)
              .then((city) => setLocation(city))
              .catch(() => setLocation('Nice'));
          },
          () => setLocation('Nice')
        );
      }
    }
  }, [userInfo]);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const query = new URLSearchParams({
          location,
          sort: 'date:asc',
        }).toString();

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/activities?${query}`);
        const data = await response.json();
        setEventsData(data.activities || []);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch activities:', error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, [location]);

  const fetchCityFromCoordinates = async (lat, lon) => {
    return 'Nice';
  };

  const handleEventClick = (activityId) => {
    navigate(`/activity/${activityId}`);
  };

  if (loading) {
    return <Typography>{t('loading_events')}</Typography>;
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {t('events_near')}
        <Link
          href={`/activities?sport=&location=${location}`}
          variant="h6"
          color="primary"
          style={{ marginLeft: '5px', textDecoration: 'none' }}
        >
          {location}
        </Link>
      </Typography>

      <Grid container spacing={4}>
        {eventsData.length > 0 ? (
          eventsData.slice(0, 4).map((event) => (
            <Grid item xs={12} sm={6} md={3} key={event._id}>
              <MiniActivityCard activity={event} />
            </Grid>
          ))
        ) : (
          <Typography>{t('no_events_found')} {location}</Typography>
        )}
      </Grid>

      <Link
        href="/activities"
        variant="body1"
        color="primary"
        style={{ display: 'block', marginTop: '16px', textAlign: 'center', textDecoration: 'none' }}
      >
        {t('see_all_events')}
      </Link>
    </div>
  );
}

export default Events;
