import React, { useState, useContext, useEffect } from 'react';
import { Dialog, TextField, Button, Box, Typography, Stepper, Step, StepLabel, Select, MenuItem, useTheme, useMediaQuery, Slider } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import { useNotification } from '../../contexts/NotificationContext';
import PlaceSearch from './PlaceSearch';
import SportSelect from './SportSelect';
import placeholderImage from '../../assets/placeholder_activity_banner.png'; // Importing the placeholder image
import { SportsSoccer, CalendarToday, AccessTime, Euro, Group, Place } from '@mui/icons-material'; // Importing icons
import cityData from '../../assets/fr_cities.json';
import { useTranslation } from 'react-i18next';


const steps = ['Info', 'Details', 'Confirm'];

const collectDateOptions = [
    'At Event Date and Time',
    '6 hours before',
    '24 hours before',
    '2 days before',
    '5 days before',
    '7 days before',
    '2 weeks before',
    '1 month before'
];

const CreateActivityForm = ({ open, handleClose }) => {
    const { t } = useTranslation();

    const { userInfo } = useContext(UserContext);
    const { showNotification } = useNotification();
    const [activeStep, setActiveStep] = useState(0);
    const [imagePreview, setImagePreview] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false); // Add this state

    const [formData, setFormData] = useState({
        title: '',
        sport: '',
        description: '',
        date: '',
        time: '',
        participants: '',
        cost: '',
        level: 'Débutant',
        city: '',
        location: '',
        locationName: '',
        imageFile: null,
        imageUrl: '',
        collectDate: 0,
        duration: 1
    });
    const [errors, setErrors] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [collectDateIndex, setCollectDateIndex] = useState(0);


    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `.pac-container { z-index: 9999 !important; }`;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const handleSliderChange = (event, newValue) => {
        setCollectDateIndex(newValue);
    };

    const selectedCollectDate = collectDateOptions[collectDateIndex]; // Get the text value for the current slider index


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: !value });
    };

    // Update the handleCollectDateChange function to ensure it snaps to the closest valid value
    const handleCollectDateChange = (event, value) => {
        const validValues = [0, 6, 24, 48, 120, 168, 336, 720];
        // Snap to the nearest valid value
        const closestValue = validValues.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        setFormData({ ...formData, collectDate: closestValue });
    };


    // Handle duration input change
    const handleDurationChange = (e) => {
        setFormData({ ...formData, duration: e.target.value });
    };

    const handleNext = () => {
        const newErrors = {};
        const currentFields = stepFields[activeStep];
        currentFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = true;
            }
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setActiveStep((prev) => prev + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prev) => prev - 1);
    };

    const handlePlaceSelected = (placeInfo) => {
        if (placeInfo) {
            const location = placeInfo.address;
            const name = placeInfo.name;

            // Split the address into parts based on commas
            const addressParts = location.split(',');

            let extractedCity = '';
            if (addressParts.length >= 3) {
                // Get the second last part of the address, which typically contains the city
                extractedCity = addressParts[addressParts.length - 2].trim();
            }

            // Remove any postal code (numbers at the start of the city name)
            const cityWithoutPostalCode = extractedCity.replace(/^\d+\s*/, '');

            // Attempt to match the city against the known cities in fr_cities.json
            const matchedCity = cityData.find(city => city.Name.toLowerCase() === cityWithoutPostalCode.toLowerCase());

            // Use the matched city name if found, otherwise fallback to the extracted city
            const city = matchedCity ? matchedCity.Name : cityWithoutPostalCode;

            // Update the formData state with the selected place and extracted city
            setFormData((prevData) => ({
                ...prevData,
                location,
                city,  // Set the extracted/matched city here
                locationName: name,
                imageUrl: placeInfo.photos && placeInfo.photos.length > 0 ? placeInfo.photos[0] : '',
                imageFile: null,
            }));

            setImagePreview(placeInfo.photos && placeInfo.photos.length > 0 ? placeInfo.photos[0] : '');
        }
    };


    const handleSubmit = async () => {
        const formDataToSubmit = new FormData();

        // Append collectDate as a single value (string)
        formDataToSubmit.append('collectDate', collectDateOptions[collectDateIndex]);

        // Append other form data
        Object.keys(formData).forEach(key => {
            if (key !== 'imageFile' && key !== 'imageUrl') {
                formDataToSubmit.append(key, formData[key]);
            }
        });

        // Handle image
        if (formData.imageUrl) {
            formDataToSubmit.append('imageUrl', formData.imageUrl);
        } else if (formData.imageFile) {
            formDataToSubmit.append('image', formData.imageFile);
        }

        // Append organizer ID
        if (userInfo && userInfo._id) {
            formDataToSubmit.append('organizer', userInfo._id);
        }

        const userToken = userInfo && userInfo.token;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/createActivity`, {
                method: 'POST',
                body: formDataToSubmit,
                headers: {
                    Authorization: `Bearer ${userInfo.sessionToken}`
                },
            });

            if (response.ok) {
                setIsSubmitted(true);
                showNotification(t('activity_under_review'), 'info');
            } else {
                const errorData = await response.json();
                showNotification(errorData?.message || t('failed_to_create_activity'), 'error');
            }
        } catch (error) {
            showNotification(t('failed_to_create_activity') + error.message, 'error');
        }
    };




    const renderError = (field) => {
        return errors[field] ? { border: '1px solid red' } : {};
    };

    const stepFields = [
        ['location', 'title', 'sport', 'level', 'description'],
        ['date', 'time', 'participants', 'cost'],
        []
    ];

    const renderStepContent = (step) => {

        if (isSubmitted) {
            return (
                <Box sx={{ marginTop: '8px', textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom>Thank you!</Typography>
                    <Typography variant="body2">
                        {t('activity_under_review')}
                        <br />
                        {t('activity_pending')}
                    </Typography>
                    <Button onClick={handleClose} variant="contained" sx={{ marginTop: '16px' }}>
                        {t('close_createActivity')}
                    </Button>
                </Box>
            );
        }

        switch (step) {
            case 0:
                return (
                    <>
                        <PlaceSearch onPlaceSelected={handlePlaceSelected} value={formData.location} />
                        <TextField
                            id="title"
                            name="title"
                            label="Title"
                            type="text"
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={formData.title} // Ensure value is bound to formData
                            onChange={handleChange}
                            sx={{ marginTop: '8px', ...renderError('title') }}
                        />

                        <SportSelect
                            value={formData.sport}
                            onChange={(option) => setFormData({ ...formData, sport: option?.value || '' })} // Bind sport to formData
                            placeholder="Select Sport"
                            sx={{ marginTop: '8px', ...renderError('sport') }}
                        />

                        <Select
                            id="level"
                            name="level"
                            value={formData.level}  // Bind value to formData.level
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            sx={{ marginTop: '8px', ...renderError('level') }}
                        >
                            <MenuItem value="Débutant">Débutant</MenuItem>
                            <MenuItem value="Intermédiaire">Intermédiaire</MenuItem>
                            <MenuItem value="Avancé">Avancé</MenuItem>
                            <MenuItem value="Expert">Expert</MenuItem>
                        </Select>

                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            type="text"
                            fullWidth
                            multiline
                            rows={2}
                            size="small"
                            variant="outlined"
                            value={formData.description}  // Bind value to formData.description
                            onChange={handleChange}
                            sx={{ marginTop: '8px', ...renderError('description') }}
                        />
                    </>
                );
            case 1:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Box sx={{ display: 'flex', gap: '8px' }}>
                            <TextField
                                id="date"
                                name="date"
                                label="Date"
                                type="date"
                                fullWidth={!isMobile}
                                size="small"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={formData.date}  // Bind value to formData.date
                                onChange={handleChange}
                                sx={{ ...renderError('date'), width: isMobile ? '100%' : '50%' }}
                            />
                            <TextField
                                id="time"
                                name="time"
                                label="Time"
                                type="time"
                                fullWidth={!isMobile}
                                size="small"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={formData.time}  // Bind value to formData.time
                                onChange={handleChange}
                                sx={{ ...renderError('time'), width: isMobile ? '100%' : '50%' }}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {/* Slider */}
                            <Slider
                                value={collectDateIndex}  // Bind value to collectDateIndex
                                onChange={handleSliderChange}
                                min={0}
                                max={collectDateOptions.length - 1}
                                step={1}
                                marks
                                valueLabelDisplay="off"
                                sx={{ width: '80%' }}
                            />

                            {/* Display selected collect date text */}
                            <Typography sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                                {t('subscription_deadline')}: <br /> {selectedCollectDate}
                            </Typography>
                        </Box>



                        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'flex-start', marginBottom: '16px' }}>
                            <TextField
                                id="participants"
                                name="participants"
                                label="Participants"
                                type="number"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={formData.participants}  // Bind value to formData.participants
                                onChange={handleChange}
                                sx={{ ...renderError('participants') }}
                            />

                            <TextField
                                id="cost"
                                name="cost"
                                label="Cost (€)"
                                type="number"
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={formData.cost}  // Bind value to formData.cost
                                onChange={handleChange}
                                sx={{ ...renderError('cost') }}
                            />

                            <TextField
                                id="duration"
                                name="duration"
                                label="Duration (hours)"
                                type="number"
                                value={formData.duration}  // Bind value to formData.duration
                                onChange={handleDurationChange}  // Use the custom change handler for this
                                fullWidth
                                size="small"
                                variant="outlined"
                                InputProps={{ inputProps: { min: 1, max: 24 } }}
                                sx={{ ...renderError('duration') }}
                            />

                        </Box>


                    </Box>
                );
            case 2:
                return (
                    <Box sx={{ marginTop: '8px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>

                            {/* Title without an icon */}
                            <Typography variant="h4">
                                {formData.title}
                            </Typography>

                            {/* Sport with an icon */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <SportsSoccer />
                                <Typography variant="body1">
                                    <strong>{t('sport')}: </strong> {formData.sport} ({formData.level})
                                </Typography>
                            </Box>

                            {/* Date and Time in the same line with icons */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <CalendarToday />
                                    <Typography variant="body1">
                                        <strong>{formData.date}</strong>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <AccessTime />
                                    <Typography variant="body1">
                                        <strong>{formData.time}</strong>
                                    </Typography>
                                </Box>

                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                {/* Duration without an icon */}
                                <Typography variant="body1">
                                    <strong>{t('subscription_deadline')}: </strong> {selectedCollectDate}
                                </Typography>
                            </Box>

                            {/* Location with an icon */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Place />
                                <Typography variant="body1">
                                    <strong>{t('location')}: </strong> {formData.locationName || formData.location}
                                </Typography>
                            </Box>

                            {/* Participants and Cost in the same line with icons */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <Group />
                                    <Typography variant="body1">
                                        <strong>{t('participants')}: </strong> {formData.participants}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <Euro />
                                    <Typography variant="body1">
                                        <strong>{t('cost')}: </strong> {formData.cost}{t('currency_txt')}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Duration without an icon */}
                            <Typography variant="body1">
                                <strong>{t('duration')}: </strong> {formData.duration} {t('hours')}
                            </Typography>

                            {/* Duration without an icon */}
                            <Typography variant="body1">
                                <strong>{t('description_createActivity')}: </strong> {formData.description}
                            </Typography>
                        </Box>
                    </Box>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Box sx={{ width: '100%', overflow: 'hidden' }}>
                {/* Image with dynamic gradient and text overlay */}
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '200px',
                        backgroundColor: imagePreview ? 'transparent' : 'transparent',
                        backgroundImage: `url(${imagePreview || placeholderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '4px 4px 0 0',
                    }}
                >
                    {/* Text overlay with dynamic gradient */}
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            color: '#fff',
                            minHeight: "50px",
                            padding: '8px',
                            background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
                        }}
                    >
                        <Typography variant="body2" sx={{ fontSize: '14px' }}>
                            {formData.locationName}
                        </Typography>
                        <Typography variant="caption" sx={{ fontSize: '12px' }}>
                            {formData.location}
                        </Typography>
                    </Box>
                </Box>

                {!isSubmitted && (
                    <>
                        <Stepper activeStep={activeStep} alternativeLabel sx={{ padding: '0 8px', marginTop: "30px" }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </>
                )}

                <Box sx={{ padding: '8px', paddingTop: '16px' }}>{renderStepContent(activeStep)}</Box>

                {!isSubmitted && (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
                            <Button disabled={activeStep === 0} onClick={handleBack}>
                                Back
                            </Button>
                            <Button onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext} variant="contained">
                                {activeStep === steps.length - 1 ? 'Create' : 'Next'}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Dialog>
    );
};

export default CreateActivityForm;
